import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import useAuth from '@hooks/useAuth';

const IndexPage = () => {
    const { user, authReady } = useAuth();
    const router = useRouter();
    const hasRedirected = useRef(false);

    useEffect(() => {
        if (!router || !authReady || hasRedirected.current) return;

        hasRedirected.current = true; // Prevent multiple redirects
        const targetPath = user ? '/labspace' : '/login';

        setTimeout(() => {
            router.push(targetPath);
        }, 2000);
    }, [router, user, authReady]);

    return (
        <div
            style={{
                backgroundColor: '#06141b',
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <img
                src="https://cdn.bfldr.com/9AK17BV1/at/mtbkww8pqf34s7th3ffmgst/Email_-_Pluto_signature_spinning_logo"
                alt="Loading Pluto"
                width="400"
            />
        </div>
    );
};

export default IndexPage;
